import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown, Menu, Checkbox } from "antd";
import { ArrowSelect } from "assets/icons/icons";
import _ from "lodash";

import { fetchShareProducts } from "store/navigation/navigationShareProducts/actions";
import { requestRetailerOrder } from "store/authorization/actions";

import useRetailers from "../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import LocationsBox from "tabs/NavigationTabs/components/LocationsBox";
import useFilters from "../../hooks/useFilters";

const ShareProducts = () => {
  const wrapperRef = useRef(null);
  const { getRemoteLogo } = useRetailers();

  const dispatch = useDispatch();

  const { manufacture, sourceType } = useSelector((state) => state.filters.filters);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { retailersOrder, watchlist, watchlistFilter } = useSelector((state) => state.authorization);
  const { shareProducts } = useSelector((state) => state.navigationShareProducts);
  const { status } = useSelector((state) => state.navigationShareProducts);

  const [manufacturers, setManufacturers] = useState([]);
  const [currentManufacturerId, setCurrentManufacturerId] = useState(null);
  const [currentManufacturerColor, setCurrentManufacturerColor] = useState("");

  const [showNumProdacts, setShowNumProdacts] = useState(true);
  const [fullTaxonomy, setFullTaxonomy] = useState(true);

  const [isOpenChangeOrder, setIsOpenChangeOrder] = useState(false);
  const [pageRetailersOrder, setPageRetailersOrder] = useState([]);

  useEffect(() => {
    if (currentManufacturerId && statusFilters === STATE_STATUSES.READY) {
      // dispatch(
      //   fetchShareProducts({
      //     product: lastFilter.product,
      //     manufacture: currentManufacturerId.toString(),
      //     showFullTaxonomy: fullTaxonomy,
      //     timePeriod: getTimePeriod(lastFilter.date),
      //   })
      // );
      if (watchlist) {
        dispatch(
          fetchShareProducts({
            product: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.product : lastFilter.product,
            manufacture: currentManufacturerId.toString(),
            showFullTaxonomy: fullTaxonomy,
            timePeriod: getTimePeriod(lastFilter.date),
            watchlist
          })
        );
      } else {
        dispatch(
          fetchShareProducts({
            product: lastFilter.product,
            manufacture: currentManufacturerId.toString(),
            showFullTaxonomy: fullTaxonomy,
            timePeriod: getTimePeriod(lastFilter.date),
            watchlist
          })
        );
      }
    }
  }, [currentManufacturerId, lastFilter, fullTaxonomy, statusFilters, dispatch]);

  useEffect(() => {
    if (shareProducts.share.length) {
      const retailers = shareProducts.share.map((item) => ({
        name: item.retailer,
        id: item.retailerId,
      }));
      const pageRetailersOrder = _.sortBy(retailers, (item) => retailersOrder.indexOf(item.id));

      setPageRetailersOrder(pageRetailersOrder);
    } else {
      setPageRetailersOrder([]);
    }
  }, [shareProducts]);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    if (manufacture.length) {
      const getManufacturers = (param) => {
        return manufacture.filter(
          (item) =>
            param ===
            lastFilter.manufacture
              .split("|")
              .map((id) => Number(id))
              .includes(Number(item.id))
        );
      };

      let manufacturers;
      if (lastFilter.manufacture.length) {
        manufacturers = getManufacturers(true);
      } else {
        manufacturers = getManufacturers(false);
      }

      const options = manufacturers
        .map((item) => ({
          lable: item.name,
          value: item.name,
          color: item.color,
          id: item.id,
        }))
        .sort((a, b) => a.lable.localeCompare(b.lable));

      let defoltName;
      let defoltId;
      let defoltColor;

      const initialValue = options.find((item) => item.lable === "Bel UK Ltd");

      if (initialValue) {
        defoltName = initialValue.lable;
        defoltId = initialValue.id;
        defoltColor = initialValue.color;
      } else {
        defoltName = options[0].lable;
        defoltId = options[0].id;
        defoltColor = options[0].color;
      }

      const selectManufacturer = [
        {
          name: "manufacturers",
          default: defoltName,
          options,
        },
      ];

      setManufacturers(selectManufacturer);
      setCurrentManufacturerId(defoltId);
      setCurrentManufacturerColor(defoltColor);
    }
  }, [manufacture, lastFilter, statusFilters]);

  const handleSetCurrentManufacturer = (values) => {
    const currentManufacturer = manufacturers[0].options.find((item) => item.lable === values["manufacturers"]);

    setCurrentManufacturerId(currentManufacturer.id);
    setCurrentManufacturerColor(currentManufacturer.color);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(pageRetailersOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const orderIds = items.map((item) => item.id);

    setPageRetailersOrder(items);
    dispatch(requestRetailerOrder({ retailerOrder: orderIds.join("|") }));
  };

  const selectList = (
    <Menu>
      <Checkbox checked={showNumProdacts} onChange={() => setShowNumProdacts(!showNumProdacts)}>
        Show No. of Products
      </Checkbox>
      <Checkbox checked={fullTaxonomy} onChange={() => setFullTaxonomy(!fullTaxonomy)}>
        Show Full Taxonomy
      </Checkbox>
    </Menu>
  );

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenChangeOrder) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenChangeOrder(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenChangeOrder]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div>
      <div className="filters-box">
        <div className="share-dropdown-wrapper">
          <Dropdown
            overlay={selectList}
            placement="bottomLeft"
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <div className="share-dropdown">
              <p>View ({Number(showNumProdacts) + Number(fullTaxonomy)})</p>
              <ArrowSelect />
            </div>
          </Dropdown>
        </div>

        <div className="trend-perion-wrapper" ref={wrapperRef}>
          <div
            style={{ marginRight: "0", marginLeft: "15px" }}
            className="trend-perion"
            onClick={() => setIsOpenChangeOrder(!isOpenChangeOrder)}
          >
            <span className="label">Change Order</span>
          </div>
          {isOpenChangeOrder ? (
            <div className="dropdown retailer" style={{ width: "400px", right: "0" }}>
              <div className="description">Drag and drop retailers to change an order.</div>
              <div className="period-box">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable direction="horizontal" droppableId="characters">
                    {(provided) => (
                      <ul className="retailers-order-box" {...provided.droppableProps} ref={provided.innerRef}>
                        {pageRetailersOrder
                          ? pageRetailersOrder.map((retailer, index) => {
                            return (
                              <Draggable key={retailer.name} draggableId={retailer.id.toString()} index={index}>
                                {(provided) => (
                                  <li
                                    className="retailer-order-item"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <p>{index + 1}</p>
                                    <img src={getRemoteLogo(retailer.name)}></img>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })
                          : null}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          ) : null}
        </div>
        {manufacturers.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status !== STATE_STATUSES.READY}
            setSelectValue={handleSetCurrentManufacturer}
            color={currentManufacturerColor}
          />
        ))}
      </div>
      <div className="wrapper-box-relative">
        {shareProducts.success ? (
          <div className="title-hint">
            You are currently viewing {shareProducts.share.length} of {sourceType.length} retailers.
          </div>
        ) : null}
        {shareProducts.success ? (
          <div className="locations-wrapper">
            {shareProducts.share.length ? (
              _.sortBy(shareProducts.share, (item) =>
                retailersOrder.map((item) => Number(item)).indexOf(item.retailerId)
              ).map((item, index) => <LocationsBox key={index} data={item} showNumProdacts={showNumProdacts} />)
            ) : (
              <div className="chart-no-data static">Looks like we don't have data for this request</div>
            )}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default ShareProducts;
