import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../../hooks/useColorTheme";

//Components
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import MediaSummaryTopChart from "./components/MediaSummaryTopChart";
import { Styles } from "./styles";
//Actions
import { fetchMediaSummaryTop } from "store/media/mediaSummaryTop/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import useFilters from "../../../../../hooks/useFilters";

//Images
import { Cancel } from "assets/icons/icons";

const MediaSummaryTop = ({ setSelectValue, arrayOfDesiredBrand, setArrayOfDesiredBrand, retailer, setRetailer }) => {
  //Selectors
  const { mediaSummaryTop, filter, status } = useSelector((state) => state.mediaSummaryTop);
  const { filter: bottomFilter } = useSelector((state) => state.mediaSummaryBottom);
  const { watchlist, watchlistFilter } = useSelector((state) => state.authorization);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { sourceType } = useSelector((state) => state.filters.filters);

  const { primaryColor } = useColorTheme();

  //States
  const [hoverEl, setHoverEl] = useState("");
  const [queryParams, setQueryParams] = useState({});

  //Const
  const dispatch = useDispatch();
  const isBrand = filter[0].value === "byBrand";

  const filteredMediaSummaryTop = useMemo(() => {
    if (isBrand || !mediaSummaryTop?.result || mediaSummaryTop.result.length === 0 || !Array.isArray(sourceType))
      return mediaSummaryTop;

    const retailerIds = new Set(sourceType.map((s) => s.id));
    return {
      ...mediaSummaryTop,
      result: mediaSummaryTop.result.filter((r) => retailerIds.has(r.id)),
    };
  }, [mediaSummaryTop, sourceType, isBrand]);

  const groupBy = {
    byRetailer: "retailer",
    byBrand: "brand",
  };

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;
    if (!!watchlist) {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: !!watchlistFilter ? watchlistFilter?.sourceType?.join("|") : lastFilter.sourceType,
        timePeriod: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.timePeriod : getTimePeriod(lastFilter.date),
        product: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.product : lastFilter.product,
        groupBy: groupBy[filter[0].value],
        showAll: bottomFilter[0].value,
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
        groupBy: groupBy[filter[0].value],
        showAll: bottomFilter[0].value,
      }));
    }
  }, [lastFilter, filter[0].value, bottomFilter[0].value, statusFilters]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchMediaSummaryTop(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  const renderSelectedEntity = () => {
    let content = "";
    let func;

    const suffix = arrayOfDesiredBrand.length > 1 ? "s" : "";

    if (isBrand) {
      content = arrayOfDesiredBrand.length + " brand" + suffix;
      func = setArrayOfDesiredBrand;
    } else {
      content = retailer[0];
      func = setRetailer;
    }

    const condition = (isBrand && arrayOfDesiredBrand.length) || (!isBrand && retailer.length);

    if (condition) {
      return (
        <div className="filter" onClick={() => func([])}>
          <span className="filter-title">{content}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  const renderChart = () => {
    return (
      <MediaSummaryTopChart
        retailer={retailer}
        setRetailer={setRetailer}
        data={filteredMediaSummaryTop}
        currentChart={filter}
        setHoverEl={setHoverEl}
        hoverEl={hoverEl}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        bottomFilter={bottomFilter}
        status={status}
      />
    );
  };
  return (
    <Styles color={primaryColor}>
      <div className="filters-box">
        <SelectBox
          filter={filter[0].value}
          data={filter[0]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
        {renderSelectedEntity()}
      </div>
      <div className="wrapper-box-relative">
        {filteredMediaSummaryTop.success ? <div className="chart-wrapper-box">{renderChart()}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default MediaSummaryTop;
