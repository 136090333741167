import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import CompareBox from "components/CompareBox";
import DiscountFilter from "components/Filters/DiscountFilter/DiscountFilter";
import SelectBox from "components/Selects/SelectBox";
import AvailabilitySummaryChart from "./components/AvailabilitySummaryChart";
import LoaderBox from "components/LoaderBox";
import useFilters from "../../../../hooks/useFilters";

//Actions
import {
  fetchAvailabilitySummary,
  fetchAvailabilitySummaryCompare,
  setAvailabilitySummaryFilter,
  addToDataAvailabilitySummary,
  addToDataAvailabilitySummaryCompare,
} from "store/availability/availabiltySummary/actions";
//Filters
import { filtersForAvailabilitySummary } from "constants/filters";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

const radioButtons = [
  {
    value: "availability",
    lable: "% Availability",
  },
  {
    value: "count",
    lable: "Count of Available Products",
  },
];

const AvailabilitySummary = () => {
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, filter, availabilitySummary, comparativeValues, controlData, controlDataCompare } = useSelector(
    (state) => state.availabilitySummary
  );
  const { watchlist, watchlistFilter } = useSelector((state) => state.authorization);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  //states
  const [comparativeValue, setComparativeValue] = useState("");
  const [isResetCompare, setIsResetCompare] = useState(false);
  const [minMaxValues, setMinMaxValues] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [minMaxControlValues, setMinMaxControlValues] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [typeOfDiscount, setTypeOfDiscount] = useState(filter[0].value);
  const [hoverEl, setHoverEl] = useState("");
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [queryParamsCompare, setQueryParamsCompare] = useState({});
  //const
  const productKey = typeOfDiscount === "availability" ? "productPercent" : "productCount";
  const currentDate = useMemo(() => {
    if (statusFilters !== STATE_STATUSES.READY) return "";
    return lastFilter && lastFilter.date.length ? getTimePeriod(lastFilter.date) : "";
  }, [statusFilters, lastFilter]);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    if (!!watchlist) {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: !!watchlistFilter ? watchlistFilter?.sourceType?.join("|") : lastFilter.sourceType,
        timePeriod: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.timePeriod : getTimePeriod(lastFilter.date),
        product: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.product : lastFilter.product,
        groupBy: filter[2].value,
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
        groupBy: filter[2].value,
      }));
    }
  }, [lastFilter, filter[2].value, statusFilters]);

  useEffect(() => {
    if (comparativeValue?.length && statusFilters === STATE_STATUSES.READY) {
      if (!!watchlist) {
        setQueryParamsCompare((prevState) => ({
          ...prevState,
          sourceType: !!watchlistFilter ? watchlistFilter?.sourceType?.join("|") : lastFilter.sourceType,
          timePeriod: comparativeValue,
          product: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.product : lastFilter.product,
          groupBy: filter[2].value,
        }));
      } else {
        setQueryParamsCompare((prevState) => ({
          ...prevState,
          sourceType: lastFilter.sourceType,
          timePeriod: comparativeValue,
          product: lastFilter.product,
          groupBy: filter[2].value,
        }));
      }
    } else {
      dispatch(addToDataAvailabilitySummaryCompare([]));
    }
  }, [comparativeValue, statusFilters]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchAvailabilitySummary(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Object.keys(queryParamsCompare).length) {
      dispatch(fetchAvailabilitySummaryCompare(queryParamsCompare));
    }
  }, [queryParamsCompare]);

  useEffect(() => {
    const arrayOfProducts = availabilitySummary.row.map((el) => el[productKey]);
    const maxValue = arrayOfProducts.length ? Math.max(...arrayOfProducts) : 0;
    const minValue = arrayOfProducts.length ? Math.min(...arrayOfProducts) : 0;
    setMinMaxValues({ minValue, maxValue });
  }, [availabilitySummary, typeOfDiscount]);

  useEffect(() => {
    const arrayOfProducts = controlData.row.map((el) => el[productKey]);
    const maxValue = arrayOfProducts.length ? Math.max(...arrayOfProducts) : 0;
    const minValue = arrayOfProducts.length ? Math.min(...arrayOfProducts) : 0;
    setMinMaxControlValues({ minValue, maxValue });
    setSelectValue({
      availability: typeOfDiscount,
      rangeValues: [minValue, maxValue],
    });
  }, [controlData, typeOfDiscount]);

  useEffect(() => {
    setIsResetCompare(false);
  }, [controlData]);

  useEffect(() => {
    if (comparativeValue?.length) {
      setIsResetCompare(true);
      setComparativeValue("");
    } else {
      setIsResetCompare(false);
    }
  }, [filter[2].value]);

  useEffect(() => {
    const min = filter[1].value[0];
    const max = filter[1].value[1];

    const filteredValues = controlData.row.filter((el) => {
      return el[productKey] >= min && el[productKey] <= max;
    });
    const filteredCompareValues = controlDataCompare.row.filter((el) => {
      return el[productKey] >= min && el[productKey] <= max;
    });

    dispatch(addToDataAvailabilitySummary(filteredValues));

    if (comparativeValue?.length) {
      dispatch(addToDataAvailabilitySummaryCompare(filteredCompareValues));
    }
  }, [filter[1].value, controlData.row, controlDataCompare.row]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);
    setArrayOfDesiredBrand([]);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    dispatch(setAvailabilitySummaryFilter(updateData));
  };

  const renderChart = () => {
    return (
      <AvailabilitySummaryChart
        data={availabilitySummary.row}
        currentChart={filter}
        setHoverEl={setHoverEl}
        hoverEl={hoverEl}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        isCompare={!!comparativeValue}
        comparativeValue={comparativeValue}
        compareData={comparativeValues.row}
        status={status}
        currentDate={currentDate}
      />
    );
  };

  return (
    <>
      <div className="filters-box" style={{ zIndex: 3 }}>
        <CompareBox
          isResetCompare={isResetCompare}
          setComparativeValue={setComparativeValue}
          disabled={status == STATE_STATUSES.PENDING}
        />

        <DiscountFilter
          data={filtersForAvailabilitySummary[0]}
          setSelectValue={setSelectValue}
          minMaxValues={minMaxValues}
          typeOfDiscount={typeOfDiscount}
          setTypeOfDiscount={setTypeOfDiscount}
          disabled={status !== STATE_STATUSES.READY}
          radioButtons={radioButtons}
          minMaxControlValues={minMaxControlValues}
        />

        <SelectBox
          filter={filter[2].value}
          data={filtersForAvailabilitySummary[1]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
      </div>
      <div className="wrapper-box-relative" style={{ marginTop: 36 }}>
        {controlData.success ? <div className="chart-wrapper-box">{renderChart()}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default AvailabilitySummary;
