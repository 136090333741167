const url = process.env.REACT_APP_URL;

export const FETCH_WATCHLIST_FILTERS = "FETCH_WATCHLIST_FILTERS";
export const fetchWatchListFilters = (data) => ({
  type: FETCH_WATCHLIST_FILTERS,
  request: {
    url: `${url}/v2/filters`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SEND_WATCHLIST = "SEND_WATCHLIST";
export const sendWatchList = (data) => ({
  type: SEND_WATCHLIST,
  request: {
    url: `${url}/auth/watchlist`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const TOGGLE_WATCHLIST = "TOGGLE_WATCHLIST";
export const toggleWatchlist = () => ({
  type: TOGGLE_WATCHLIST,
});

export const SET_WATCHLIST_FILTERS = "SET_WATCHLIST_FILTERS";
export const setWatchList = (filters) => ({
  type: SET_WATCHLIST_FILTERS,
  data: filters,
});