import { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUniversalFilters } from "store/universalFilters/actions";
import { STATE_STATUSES } from "utils/statuses";
import {
  updateFilters as updateTempFilters,
  resetFilters as resetTempFilters,
  updateEqualStatus,
  clearEqualStatus,
} from "store/tempFilters/actions";
import { updateFilters as updateMainFilters, setIsValidated } from "store/filters/actions";
import { refreshAuthMe, setAuthRefreshStatus, setWatchlist } from "store/authorization/actions";
import { getWatchlistInArray } from "utils/getWatchlistInArray";

const fieldNames = [
  'sourceType',
  'category',
  'manufacture',
  'productBrand',
  'product',
];

export default function useFilters() {
  const {
    filters: universalFilters,
    status: universalFiltersStatus,
    status,
  } = useSelector((state) => state.universalFilters);
  const {
    watchlistFilter,
    refreshStatus,
  } = useSelector((state) => state.authorization);
  const { status: statusTempFilters, filters: tempFilters, equalValues } = useSelector((state) => state.tempFilters);
  const { status: statusMainFilters, filters: mainFilters, titleDateStatus, isValidated } = useSelector((state) => state.filters);
  const { lastFilter: mainLastFilter } = mainFilters;
  const { lastFilter: tempLastFilter } = tempFilters;
  const dispatch = useDispatch();
  const [isReset, setIsReset] = useState(false);

  const resetUniversalObj = useCallback((countryId, companyId) => {
    dispatch(resetTempFilters());
    dispatch(clearEqualStatus());
    dispatch(fetchUniversalFilters({ countryId, companyId }));
    setIsReset(true);
  },[dispatch]);

  const getUniversalFiltersObj = useCallback(
    (isUseWatchList = false, forceWatchlistFilter) => {
      if (universalFiltersStatus !== STATE_STATUSES.READY || !universalFilters.manufacture) return {};
      if (!isUseWatchList) return universalFilters;
      const combinedWatchlistFilter = forceWatchlistFilter || watchlistFilter;
      const filteredCategory = universalFilters.category.filter((c) => combinedWatchlistFilter.category.includes(c.id));

      const filteredManufacture = universalFilters.manufacture.filter((m) =>
        combinedWatchlistFilter.manufacture.includes(m.id)
      );
      const filteredBrand = universalFilters.productBrand.filter((b) => combinedWatchlistFilter.productBrand.includes(b.id));
      const filteredSourceType = universalFilters.sourceType.filter((s) => combinedWatchlistFilter.sourceType.includes(s.id));
      let filteredProducts = universalFilters.product.filter((p) =>
        filteredCategory.map((c) => c.id).includes(p.categoryId)
      );

      filteredProducts = filteredProducts.filter((p) =>
        filteredSourceType.map((r) => r.id).some((r) => p.retailers.includes(r))
      );

      filteredProducts = filteredProducts.filter((p) =>
        filteredManufacture.map((m) => m.id).includes(p.manufacturerId) || (p.ownLabelManufacturerId && filteredManufacture.map((m) => m.id).includes(p.ownLabelManufacturerId))
      );  
      filteredProducts = filteredProducts.filter((p) => filteredBrand.map((b) => b.id).includes(p.brandId));

      return {
        ...universalFilters,
        category: filteredCategory,
        manufacture: filteredManufacture,
        productBrand: filteredBrand,
        sourceType: filteredSourceType,
        product: filteredProducts,
        productCount: filteredProducts.length,
      };
    },
    [universalFiltersStatus, universalFilters, watchlistFilter]
  );

  const filters = useMemo(() => {
    let resultFilters = universalFilters;

    if (isReset && universalFiltersStatus === STATE_STATUSES.READY) {
      const defaultFilters = JSON.parse(JSON.stringify(universalFilters));
      if (resultFilters.lastFilter) {
        ['productBrand','product'].forEach(f => {
          const selectedItems = defaultFilters.lastFilter[f].split('|');
          const selectedFilterOptions = defaultFilters[f].filter(item => selectedItems.includes(`${item.id}`)).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);
          defaultFilters[f] = [ ...selectedFilterOptions];
        })
      }
      dispatch(updateMainFilters(defaultFilters));
      setIsReset(false);
    } else {
      resultFilters = statusTempFilters === STATE_STATUSES.READY ? tempFilters : mainFilters;
    }

    if (resultFilters.lastFilter) {
      fieldNames.forEach(f => {
        const selectedItems = resultFilters.lastFilter[f].split('|');
        const selectedFilterOptions = resultFilters[f].filter(item => selectedItems.includes(`${item.id}`)).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);
        const unSelectedFilterOptions = resultFilters[f].filter(item => !selectedItems.includes(`${item.id}`)).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);

        resultFilters[f] = [ ...selectedFilterOptions, ...unSelectedFilterOptions];
      })
    }
    return resultFilters;
  }, [mainFilters, tempFilters, statusTempFilters, isReset, universalFiltersStatus, dispatch, universalFilters]);

  const statusFilters = useMemo(
    () => (statusTempFilters === STATE_STATUSES.INIT ? statusMainFilters : statusTempFilters),
    [statusMainFilters, statusTempFilters]
  );

  const defaultLastFilter = useMemo(() => universalFilters?.lastFilter || [], [universalFilters]);

  const lastFilter = useMemo(() => {
    const lFilter = statusTempFilters === STATE_STATUSES.INIT ? mainLastFilter : tempLastFilter;
    return lFilter || defaultLastFilter;
  }, [mainLastFilter, tempLastFilter, statusTempFilters, defaultLastFilter]);

  const getFilters = useCallback(
    (filters) => {
      const { watchlist, watchlistFilter: forceWatchlistFilter } = filters;
      const universalFiltersObj = getUniversalFiltersObj(watchlist, forceWatchlistFilter);
      if (status !== STATE_STATUSES.READY) return {};
      let filteredProducts = universalFiltersObj.product.filter((p) =>
        filters.category.split("|").includes(`${p.categoryId}`)
      );
      filteredProducts = filteredProducts.filter((p) =>
        filters.sourceType.split("|").some((r) => p.retailers.includes(Number(r)))
      );

      let manufacture = universalFiltersObj.manufacture;

      if (filters.manufacture.indexOf("All") < 0)
        filteredProducts = filteredProducts.filter((p) =>
          filters.manufacture.split("|").includes(`${p.manufacturerId}`)
        );

      let productBrand = universalFiltersObj.productBrand.filter((b) =>
        filteredProducts.map((p) => p.brandId).includes(b.id)
      );

      if (filters.productBrand.indexOf("All") < 0)
        filteredProducts = filteredProducts.filter((p) => filters.productBrand.split("|").includes(`${p.brandId}`));

      filteredProducts =
        filters.productGroup && filters.productGroup.length > 0
          ? filteredProducts.filter((p) =>
              filters.productGroup.split("|").some((pg) => p.productGroupIds.includes(Number(pg)))
            )
          : filteredProducts;

      const filteredMenu = {
        ...universalFiltersObj,
        product: filteredProducts,
        productBrand,
        manufacture,
      };

      const optionName = ["sourceType", "category", "manufacture", "productBrand", "product"];
      const lastFilter = optionName.reduce((acc, opName) => {
        let filteredValue = "";
        if (acc[opName].indexOf("All") < 0) {
          const testValue = filteredMenu[opName].map((item) => item.id);
          const compareValue =  acc[opName].split("|");
          filteredValue = opName === "sourceType" ?
          testValue.filter((v) => compareValue.includes(`${v}`)).join("|") :
          testValue.filter((v) => compareValue.includes(`${v}`)).sort((a, b) => a > b ? 1 : -1).join("|")
            
        }
        return {
          ...acc,
          [opName]:
            acc[opName].indexOf("All") >= 0 ? opName === "sourceType" ?
            filteredMenu[opName].map((item) => item.id).join("|") :
            filteredMenu[opName].map((item) => item.id).sort((a, b) => a > b ? 1 : -1).join("|") :
            filteredValue,
        };
      },
      {
        productGroup: '',
        ...filters
      });

      const result = {
        ...filteredMenu,
        lastFilter,
      };
      return result;
    },
    [getUniversalFiltersObj, status]
  );

  const setRefreshStatus = useCallback(
    (status) => {
      dispatch(setAuthRefreshStatus(status));
    },
    [dispatch]
  );

  const fetchMainFilters = useCallback(
    (filters) => {
      if (status !== STATE_STATUSES.READY) return;
      const newFilters = getFilters(filters);
      dispatch(resetTempFilters());
      dispatch(refreshAuthMe({ filter: newFilters.lastFilter, isStart: filters.isStart }));
      dispatch(updateMainFilters(newFilters));
      dispatch(clearEqualStatus());
    },
    [dispatch, getFilters, status]
  );

  const fetchTempFilters = useCallback(
    (filters) => {      
      const newFilters = getFilters(filters);
      dispatch(updateTempFilters(newFilters));
      if (!newFilters.product || newFilters.product.length ===0){
        dispatch(setIsValidated(false))
      }
    },
    [dispatch, getFilters]
  );

  const validateFilters = useCallback(
    (filters) => {      
      const newFilters = getFilters(filters);
      const isValidated = !!filters.product && !!newFilters.product && newFilters.product.length > 0;
      dispatch(setIsValidated(isValidated));
      return isValidated;
    },
    [dispatch, getFilters]
  );

  const setEqualValues = useCallback(
    (value) => {
      dispatch(updateEqualStatus(value));
    },
    [dispatch]
  );

  const getEqualValues = useCallback(() => {
    return equalValues;
  }, [equalValues]);

  const setWatchlistFilter = useCallback(
    (filterParams) => {
      const { watchlist: isUseWatchList } = filterParams;
      const newParams = isUseWatchList
        ? {
            ...lastFilter,
            ...filterParams.watchlistFilter,
            watchlist: isUseWatchList,
          }
        : {
          ...lastFilter,
          ...defaultLastFilter,
          watchlist: isUseWatchList,
        };

      fetchMainFilters(newParams);
      dispatch(setWatchlist({ watchlist: isUseWatchList, ...(isUseWatchList ? { watchlistFilter: getWatchlistInArray(filterParams.watchlistFilter) } : {})  }));
      setRefreshStatus(STATE_STATUSES.PENDING);
    },
    [lastFilter, fetchMainFilters, dispatch, setRefreshStatus, defaultLastFilter]
  );

  const isFilterChange = useMemo(() => {
    for (const k of Object.keys(equalValues)) {
      if (equalValues[k]) return true;
    }
    return false;
  }, [equalValues]);

  const resetSelectedFilters = useCallback(() => {
    dispatch(resetTempFilters());
    dispatch(clearEqualStatus());
  }, [dispatch]);

  return {
    universalFiltersStatus,
    filters,
    statusFilters,
    lastFilter,
    mainLastFilter,
    statusMainFilters,
    getFilters,
    fetchMainFilters,
    fetchTempFilters,
    setWatchlistFilter,
    setRefreshStatus,
    setEqualValues,
    getEqualValues,
    resetSelectedFilters,
    refreshStatus,
    isFilterChange,
    resetUniversalObj,
    defaultLastFilter,
    statusTempFilters,
    mainFilters,
    tempFilters,
    tempLastFilter,
    titleDateStatus,
    isValidated,
    validateFilters,
  };
}
