import { success, error } from "redux-saga-requests";
import {
  FETCH_WATCHLIST_FILTERS,
  SEND_WATCHLIST,
  SET_WATCHLIST_FILTERS,
  TOGGLE_WATCHLIST
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  filters: {
    category: [],
    manufacture: [],
    productBrand: [],
    sourceType: [],
  },
  status: STATE_STATUSES.READY,
  enableWatchlist: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHLIST_FILTERS: {
      return processReducer(state);
    }
    case success(FETCH_WATCHLIST_FILTERS): {
      return {
        ...state,
        filters: action.data.filters,
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case SET_WATCHLIST_FILTERS: {
      return {
        ...state,
        filters: action.data,
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_WATCHLIST_FILTERS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SEND_WATCHLIST: {
      return processReducer(state);
    }
    case success(SEND_WATCHLIST): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
        enableWatchlist: true
      };
    }
    case error(SEND_WATCHLIST): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case TOGGLE_WATCHLIST: {
      return {
        ...state,
        enableWatchlist: false
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
