import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCountry from "../../../../hooks/useCountry";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

//Components
import SelectBox from "components/Selects/SelectBox";
import DiscountFilter from "components/Filters/DiscountFilter/DiscountFilter";
import SummaryTabBubble from "./components/SummaryTabBubble";
import TopMoversWithFilter from "tabs/PromotionsTabs/PromotionsTopTabs/SummaryTab/components/TopMoversWithFilter";
import LoaderBox from "components/LoaderBox";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

//Constants
import { filtersForPromotions } from "constants/filters";
//Actions
import { fetchSummaryPromotions, setSummaryFilter } from "store/promotions/summaryPromotions/actions";

const radioButtons = (currencySymbol = "£") => [
  {
    value: "percent",
    lable: "% Discount",
  },
  {
    value: "value",
    lable: `${currencySymbol} Discount`,
  },
];

const SummaryTab = () => {
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, filter } = useSelector((state) => state.summaryPromotions);
  const { status: statusAuth, watchlist, watchlistFilter } = useSelector((state) => state.authorization);
  const { status: statusFilters } = useSelector((state) => state.filters);
  const fetchRef = useRef();
  const { cachedSummaryPromotions: summaryPromotions } = useCache();
  const { currentCurrency } = useCountry();

  //states
  const [hoverEl, setHoverEl] = useState("");
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const queryParamsRef = useRef();
  const [minMaxValues, setMinMaxValue] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [minMaxControlValues, setMinMaxControlValues] = useState({
    minValue: 0,
    maxValue: 0,
  });
  const [typeOfDiscount, setTypeOfDiscount] = useState(filter[1].value);

  const handleTypeOfDiscount = (...args) => {
    fetchRef.current = false;
    setTypeOfDiscount(...args);
  };
  const handleArrayOfDesiredBrand = (...args) => {
    fetchRef.current = false;
    setArrayOfDesiredBrand(...args);
  };
  const { setRefreshStatus, refreshStatus, lastFilter } = useFilters();

  useEffect(() => {
    const key = typeOfDiscount === "percent" ? "averageDiscountPercent" : "averageDiscount";
    const dataToFormat = summaryPromotions[filter[2].value].data;
    const arrayOfDiscount = dataToFormat.map((el) => el[key]);

    const minValue = arrayOfDiscount.length ? Math.min(...arrayOfDiscount) : 0;
    const maxValue = arrayOfDiscount.length ? Math.max(...arrayOfDiscount) : 0;

    setMinMaxValue({ minValue, maxValue });
  }, [summaryPromotions, typeOfDiscount, filter]);

  useEffect(() => {
    const minValue = summaryPromotions.range[typeOfDiscount].min;
    const maxValue = summaryPromotions.range[typeOfDiscount].max;

    setMinMaxControlValues({ minValue, maxValue });
  }, [summaryPromotions, typeOfDiscount]);

  useEffect(() => {
    const discountRange = filter[3].value.length ? `${filter[3].value[0]}-${filter[3].value[1]}` : "All";
    const mechanic =
      filter[0].value === "Price Cut"
        ? "1"
        : filter[0].value === "Multibuy"
        ? "2"
        : filter[0].value === "Other"
        ? "3"
        : "All";

    if (
      statusFilters === STATE_STATUSES.READY &&
      statusAuth === STATE_STATUSES.PENDING &&
      status !== STATE_STATUSES.PENDING
    ) {
      fetchRef.current = false;
    } else if (
      (statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.READY && !fetchRef.current) ||
      refreshStatus === STATE_STATUSES.PENDING
    ) {
      fetchRef.current = true;
      setRefreshStatus(STATE_STATUSES.READY);

      if (watchlist) {
        queryParamsRef.current = {
          ...queryParamsRef.current,
          sourceType: !!watchlistFilter ? watchlistFilter?.sourceType?.join("|") : lastFilter.sourceType,
          timePeriod: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.timePeriod : getTimePeriod(lastFilter.date),
          product: !!localStorage?.getItem('LastFilter') ? JSON?.parse(localStorage?.getItem('LastFilter'))?.product : lastFilter.product,
          mechanic,
          discountType: filter[1].value,
          discountRange,
        };
      } else {
        queryParamsRef.current = {
          ...queryParamsRef.current,
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriod(lastFilter.date),
          product: lastFilter.product,
          mechanic,
          discountType: filter[1].value,
          discountRange,
        };
      }
      if (Object.keys(queryParamsRef.current).length) {
        dispatch(fetchSummaryPromotions(queryParamsRef.current));
      }
    }
    setArrayOfDesiredBrand([]);
    setRetailer([]);
  }, [lastFilter, filter, dispatch, status, statusAuth, statusFilters, refreshStatus, setRefreshStatus]);

  //set values for filters
  const setSelectValue = (values) => {
    const value = Object.keys(values);
    handleArrayOfDesiredBrand([]);
    setRetailer([]);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });

    dispatch(setSummaryFilter(updateData));
  };

  const renderChart = () => {
    return (
      <SummaryTabBubble
        data={summaryPromotions}
        currentChart={filter}
        setHoverEl={setHoverEl}
        hoverEl={hoverEl}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        retailer={retailer}
        setRetailer={setRetailer}
      />
    );
  };

  const renderMovers = () => {
    return (
      <TopMoversWithFilter
        data={summaryPromotions}
        currentChart={filter}
        retailer={retailer}
        setSelectValue={setSelectValue}
        filter={filter[4]}
      />
    );
  };

  return (
    <>
      <div className="filters-box">
        {filtersForPromotions.map((item, index) => {
          if (index === 1) {
            return (
              <DiscountFilter
                key={index}
                data={item}
                setSelectValue={setSelectValue}
                minMaxValues={minMaxValues}
                typeOfDiscount={typeOfDiscount}
                setTypeOfDiscount={handleTypeOfDiscount}
                disabled={status !== STATE_STATUSES.READY}
                radioButtons={radioButtons(currentCurrency)}
                decimalStep
                minMaxControlValues={minMaxControlValues}
                resetWithEmptyArr
                isOnPromotions={true}
              />
            );
          } else {
            return (
              <SelectBox
                filter={filter[index].value}
                key={index}
                data={item}
                setSelectValue={setSelectValue}
                disabled={status !== STATE_STATUSES.READY}
              />
            );
          }
        })}
      </div>

      <div className="wrapper-box-relative">
        {Object.entries(summaryPromotions[filter[2].value].promotioners).length ? (
          <div className="chart-wrapper-box">
            {renderChart()}
            {renderMovers()}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default SummaryTab;
